.footerComponent {
  display: flex;
  height: auto;
  width: 40%;
  justify-content: flex-start;
  transform-origin: 10px 40px;
  transform: rotate(-270deg);
  position: absolute;
  top: 0;
  margin-left: 1%;
  border-top: #1f2421 4px solid;
}

.footerLinks {
  text-decoration: none;
  color: #1f2421;
  font-family: mostra-nuova, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.5vw;
  padding-right: 20px;
}

.footerLinks:hover {
  color: #1f2421;
}



@media only screen and (max-width: 768px){
  .footerComponent {
    display: flex;
    height: 7vw;
    width: 100vw;
    justify-content: center;
    transform-origin: 1px 3px;
    transform: rotate(0deg);
    position: absolute;
    top: 0;
    left:0;
    border-bottom: #1f2421 4px solid;
    margin-left: 0%;
    text-align: center;
  }

  .footerLinks {
    text-decoration: none;
    color: #1f2421;
    font-family: mostra-nuova, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 4vw;
    padding-right: 10px;

  }
  .footerLinks:hover {
    color: #1f2421;
  }
}

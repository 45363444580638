.projects {
  display: grid;
  grid-template-rows: 1fr 0.2fr 4fr;
  height: 100vh;
  width: 100vw;
  justify-content: center;
}

.projectArticle {
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 90vw;
  height: 50vh;
  font-family: mostra-nuova, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1vw;
  transition: 1s cubic-bezier(1, -1, 0, 1);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 50%);
}
.heading {
  border-bottom: solid 10px #30321c;
  font-family: mostra-nuova, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 7vw;
  color: #30321c;
}
.projectDescription {
  font-size: 4em;
  color: rgb(0, 0, 0);
  font-family: mostra-nuova, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.content {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 50%);
  margin-left: 2%;
  border-radius: 10px;
  border: black 10px solid;
  background-color: transparent;
  transition: 1s cubic-bezier(1, -1, 0, 1);
  display: grid;
  grid-template-rows: 0.5fr 1fr 0.5fr 0.5fr;
  box-shadow: 10px 10px 20px #30321c;
}

.content p {
  font-family: mostra-nuova, sans-serif;
  font-weight: 500;
  margin-left: 1%;
  font-style: normal;
  color: #1f2421;
  font-size: 1.25vw;
}
.content h2 {
  margin-left: 2%;
  font-family: mostra-nuova, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #1f2421;
  font-size: 2vw;
}

.screenshot {
  height: 100%;
  width: auto;
  transition: transform 2.5s;
  border-radius: 10px;
}

.screenshot:hover {
  transform: scale(1.1);
}

.screenshot_container {
  border-radius: 10px;

  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 100% 100%, 0% 100%);
  transition: 1s cubic-bezier(1, -1, 0, 1);
}
/* .screenshot_container:hover {
  clip-path: polygon(0% 0%, 0 0, 0 49%, 0 100%, 0% 100%);
} */

.projectLinks {
  position: relative;
  display: flex;
  justify-content: space-around;
}
.projectLinks a {
  font-family: mostra-nuova, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #1f2421;
  font-size: 1vw;
}
.projectLinks a:hover {
  color: #1f2421;
}

.nextButton {
  animation: pulse-animation 1.5s infinite;
  background: transparent;
  border: rgb(93, 93, 93) 4px solid;
  border-radius: 10px;
  width: 10vw;
  color: rgb(93, 93, 93);
  font-family: mostra-nuova, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 2vw;
  justify-self: right;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(93, 93, 93, 0.466);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}

@keyframes img-zoom {
  0% {
    box-shadow: 0 0 0 0px rgba(93, 93, 93, 0.466);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}

@media only screen and (max-width: 768px){


  .projects {
    display: grid;
    grid-template-rows: 1fr 0.2fr 4fr;
    height: 100vh;
    width: 100vw;
    justify-content: center;
  }
  
  .projectArticle {
    display: flex;
    flex-direction: column-reverse;
    align-content: flex-start;
    justify-content: flex-end;
    width: 100vw;
    height: 90vh;
    font-family: mostra-nuova, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1vw;
    transition: 1s cubic-bezier(1, -1, 0, 1);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 50%);
    text-align: center;
    margin-top: 5vh;
  }
  .heading {
    border-bottom: solid 10px #30321c;
    font-family: mostra-nuova, 
    sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 10vw;
    color: #30321c;
    width: 100%;
    margin-top: 10vw;
    height: fit-content;
  }
  .projectDescription {
    font-size: 4em;
    color: rgb(0, 0, 0);
    font-family: mostra-nuova, sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .content {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 50%);
    margin-left: 0%;
    border-radius: 10px;
    border: none;
    background-color: transparent;
    transition: 1s cubic-bezier(1, -1, 0, 1);
    display: grid;
    grid-template-rows: 0.5fr 1fr 0.5fr;
    box-shadow: 10px 10px 20px #30321c;
  }
  
  .content p {
    font-family: mostra-nuova, sans-serif;
    font-weight: 500;
    margin-left: 1%;
    font-style: normal;
    color: #1f2421;
    font-size: 5vw;
  }
  .content h2 {
    margin-left: 2%;
    font-family: mostra-nuova, sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #1f2421;
    font-size: 7vw;
  }
  
  .screenshot {
    height: 100%;
    width: 100vw;
    transition: transform 2.5s;
    border-radius: 10px;
  }
  
  .screenshot:hover {
    transform: scale(1.1);
  }
  
  .screenshot_container {
    border-radius: 10px;
  
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 100% 100%, 0% 100%);
    transition: 1s cubic-bezier(1, -1, 0, 1);
  }
  /* .screenshot_container:hover {
    clip-path: polygon(0% 0%, 0 0, 0 49%, 0 100%, 0% 100%);
  } */
  
  .projectLinks {
    position: relative;
    display: flex;
    justify-content: space-around;
  }
  .projectLinks a {
    font-family: mostra-nuova, sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #1f2421;
    font-size: 5vw;
  }
  .projectLinks a:hover {
    color: #1f2421;
  }
  
  .nextButton {
    animation: pulse-animation 1.5s infinite;
    background: transparent;
    border: rgb(93, 93, 93) 4px solid;
    border-radius: 10px;
    width: 40vw;
    color: rgb(93, 93, 93);
    font-family: mostra-nuova, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 9vw;
    justify-self: right;
    position:absolute;
    bottom: 0;
    z-index: 1;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(93, 93, 93, 0.466);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
  }
  
  @keyframes img-zoom {
    0% {
      box-shadow: 0 0 0 0px rgba(93, 93, 93, 0.466);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
  }



}
